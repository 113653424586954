import React from "react";
import FullscreenClose from "../../assets/icons/FullscreenClose";
import FullscreenOpen from "../../assets/icons/FullscreenOpen";

type FullScreenType = {
   active: boolean;
};

const FullScreen = ({ active }: FullScreenType) => {
   return active ? (
      <>
         <FullscreenClose height={18} width={18} /> Close
      </>
   ) : (
      <>
         <FullscreenOpen height={18} width={18} /> Fullscreen
      </>
   );
};

export default FullScreen;
