import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { LoginSubmitType } from "../../../pages/login/Login";
import instance from "../../../service/instance";
import customCatch from "../../../utils/customCatch";
import { StoreStateType } from "../../app/store";
import { GlobalAuthorizationType } from "../../app/store.types";
import { toast } from "sonner";

const initialState: GlobalAuthorizationType = {
   isLoading: false,
   error: null,
};

export const userLogin = createAsyncThunk("login/userLogin", async (payload: LoginSubmitType) => {
   try {
      const response = await instance.post("/Authentication/login", {
         username: payload.email,
         password: payload.password,
      });
      return {
         accessToken: response?.data?.token.accessToken,
         refreshToken: response?.data?.token.refreshToken,
      };
   } catch (err) {
      customCatch(err as AxiosError, "Wrong username or password");
   }
});

const loginSlice = createSlice({
   name: "login",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(userLogin.pending, (state) => {
         return { ...state, isLoading: true, error: null };
      });
      builder.addCase(userLogin.fulfilled, (state, action) => {
         return {
            ...state,
            isLoading: false,
            accessToken: action.payload?.accessToken,
            refreshToken: action.payload?.refreshToken,
         };
      });
      builder.addCase(userLogin.rejected, (state, action) => {
         return { ...state, isLoading: false, error: action.error.message as string };
      });
   },
});

export const loginSliceState = (state: StoreStateType) => state.login;

export default loginSlice.reducer;
