import styled from "styled-components";

type LoaderContainerType = {
   $isFullPage: boolean;
   $minHeight: number | undefined;
   $hasBg: boolean;
   $isTransparent?: boolean;
   $isOverlay?: boolean;
};

export const LoaderContainer = styled.div<LoaderContainerType>`
   width: 100%;
   height: ${({ $isFullPage }) => ($isFullPage ? "100%" : "auto")};
   min-height: ${({ $minHeight }) => $minHeight && `${$minHeight}px`};
   background-color: ${({ $hasBg, theme: { section } }) => (!$hasBg ? "transparent" : "inherit")};
   position: ${({ $isOverlay }) => ($isOverlay ? "absolute" : "relative")};
   top: ${({ $isOverlay }) => ($isOverlay ? "0" : "auto")};
   left: ${({ $isOverlay }) => ($isOverlay ? "0" : "auto")};
   z-index: 9;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   ${({ $isTransparent, theme: { section } }) =>
      $isTransparent &&
      `
      &::before {
         content: '';
         position: absolute;
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
         background-color: ${section};
         opacity: 0.5;
         z-index: -1;
      }
   `}
`;

export const Loader100vh = styled.div`
   height: 100vh;
`;

export const MessageContainer = styled.p`
   text-align: center;
   color: ${({ theme }) => theme.text};
   font-size: 16px;
   font-weight: 500;
   margin-top: 16px;
`;
