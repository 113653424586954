type ThemeType = {
   [key: string]: {
      background: string;
      text: string;
      textSecondary: string;
      textTertiary: string;
      textQuaternary: string;
      statisticsText: string;
      textAlternative: string;
      header: string;
      section: string;
      inputBackground: string;
      inputBorderColor: string;
      rememberMeTextColor: string;
      forgotYourPassTextColor: string;
      checkboxOnColor: string;
      dropdownHoverColor: string;
      tableBorder: string;
      tableSecondElement: string;
      statisticsTextColor: string;
      modalTheme: string;
      modalBackdrop: string;
      buttonPrimaryColor: string;
      buttonSecondaryColor: string;
      buttonSecondaryText: string;
      profileSectionColor: string;
      profileSectionBorder: string;
      missingInfoColor: string;
      breakpointXS: string;
      breakpointS: string;
      breakpointM: string;
      breakpointL: string;
      breakpointHeightS: string;
      statusDone: string;
      statusFailed: string;
   };
};

const themes: ThemeType = {
   Light: {
      background: "#EEEFF2",
      text: "black",
      textSecondary: "#3C3C3C",
      textTertiary: "#3C3C3C",
      textQuaternary: "#8092A5",
      statisticsText: "#969BA0",
      textAlternative: "#969BA0",
      statisticsTextColor: "#000000",
      header: "rgba(220,220,220,0.8)",
      section: "#FFF",
      inputBackground: "#fbfbfb",
      inputBorderColor: "#eeeff2",
      dropdownHoverColor: "whitesmoke",
      rememberMeTextColor: "#A09FA7",
      forgotYourPassTextColor: "#EC6728",
      checkboxOnColor: "#292A2D",
      modalTheme: "#1C1B17",
      modalBackdrop: "rgba(112, 112, 112, 0.7)",
      tableBorder: "#DDDFE1",
      tableSecondElement: "#FAFAFA",
      buttonPrimaryColor: "#000000",
      buttonSecondaryColor: "white",
      buttonSecondaryText: "black",
      profileSectionColor: "#A8BECD",
      profileSectionBorder: "#e8eaf0",
      missingInfoColor: "#F8DDDD",
      statusDone: "#7bbd39",
      statusFailed: "orange",
      //
      breakpointXS: "450px",
      breakpointS: "600px",
      breakpointM: "1000px",
      breakpointL: "1200px",
      //
      breakpointHeightS: "750px",
   },
};

export default themes;
