import React from "react";
import { IconContainer } from "./Icons.styled";
import { ALUMINIUM } from "../../constants/color";

type LogoutIconIconType = {
   width?: number;
   height?: number;
};

const LogoutIcon = ({ width, height }: LogoutIconIconType) => {
   return (
      <IconContainer>
         <svg width={width} height={height} viewBox="0 0 20 20" fill="none">
            <path
               fillRule="evenodd"
               clipRule="evenodd"
               d="M13.0937 10C13.0937 9.85081 13.0345 9.70774 12.929 9.60225C12.8235 9.49676 12.6804 9.4375 12.5312 9.4375H4.30149L5.77224 8.1775C5.82838 8.12944 5.8745 8.07078 5.90798 8.0049C5.94145 7.93901 5.96162 7.86717 5.96733 7.79349C5.97304 7.7198 5.96418 7.64572 5.94126 7.57546C5.91834 7.5052 5.8818 7.44014 5.83374 7.384C5.78568 7.32786 5.72703 7.28174 5.66114 7.24826C5.59525 7.21479 5.52341 7.19462 5.44973 7.18891C5.37604 7.1832 5.30196 7.19206 5.2317 7.21498C5.16144 7.2379 5.09638 7.27444 5.04024 7.3225L2.41524 9.5725C2.35349 9.62531 2.30391 9.69086 2.26992 9.76466C2.23593 9.83846 2.21832 9.91875 2.21832 10C2.21832 10.0813 2.23593 10.1615 2.26992 10.2353C2.30391 10.3091 2.35349 10.3747 2.41524 10.4275L5.04024 12.6775C5.15362 12.7746 5.30092 12.8226 5.44973 12.8111C5.59854 12.7996 5.73667 12.7294 5.83374 12.616C5.93081 12.5026 5.97886 12.3553 5.96733 12.2065C5.9558 12.0577 5.88562 11.9196 5.77224 11.8225L4.30224 10.5625H12.5312C12.6804 10.5625 12.8235 10.5032 12.929 10.3977C13.0345 10.2923 13.0937 10.1492 13.0937 10Z"
               fill={ALUMINIUM}
            />
            <path
               d="M8.03125 7C8.03125 7.5265 8.03125 7.78975 8.158 7.9795C8.21259 8.06108 8.28267 8.13116 8.36425 8.18575C8.554 8.3125 8.81725 8.3125 9.34375 8.3125H12.5312C12.9788 8.3125 13.408 8.49029 13.7245 8.80676C14.041 9.12323 14.2188 9.55245 14.2188 10C14.2188 10.4476 14.041 10.8768 13.7245 11.1932C13.408 11.5097 12.9788 11.6875 12.5312 11.6875H9.34375C8.81725 11.6875 8.554 11.6875 8.36425 11.8135C8.28259 11.8683 8.21251 11.9386 8.158 12.0205C8.03125 12.2103 8.03125 12.4735 8.03125 13C8.03125 15.121 8.03125 16.1823 8.6905 16.8408C9.349 17.5 10.4095 17.5 12.5305 17.5H13.2805C15.403 17.5 16.4628 17.5 17.122 16.8408C17.7813 16.1823 17.7812 15.121 17.7812 13V7C17.7812 4.879 17.7813 3.81775 17.122 3.15925C16.4628 2.5 15.4023 2.5 13.2812 2.5H12.5312C10.4095 2.5 9.349 2.5 8.6905 3.15925C8.03125 3.81775 8.03125 4.879 8.03125 7Z"
               fill={ALUMINIUM}
            />
         </svg>
      </IconContainer>
   );
};

export default LogoutIcon;
