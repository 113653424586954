import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import paths from "../../routes/paths";
import StageFrontLogo from "./StageFrontLogo";
import StageFrontText from "./StageFrontText";

const StageFrontFullLogoContainer = styled.div<{ $isSplash: boolean | undefined }>`
   display: flex;
   flex-direction: column;
   gap: 10px;
   max-width: 100%;

   @media only screen and (max-width: ${({ theme: { breakpointM } }) => breakpointM}) {
      gap: ${({ $isSplash }) => ($isSplash ? "20px" : "0px")};
      padding: ${({ $isSplash }) => ($isSplash ? "0px 20px" : "0px")};
   }
`;

const StageFrontFullLogoCentered = styled.div<{ $interaction: boolean }>`
   text-align: center;
   cursor: ${({ $interaction }) => ($interaction ? "pointer" : "default")};
`;

type StageFrontFullLogoType = {
   interaction?: boolean;
   isSplash?: boolean;
};

const StageFrontFullLogo = ({ interaction = true, isSplash }: StageFrontFullLogoType) => {
   const navigate = useNavigate();
   const navigateToLogin = () => interaction && navigate(paths.login);

   return (
      <StageFrontFullLogoContainer $isSplash={isSplash} onClick={navigateToLogin}>
         <StageFrontFullLogoCentered $interaction={interaction}>
            <StageFrontLogo width="60%" />
         </StageFrontFullLogoCentered>
         <StageFrontText />
      </StageFrontFullLogoContainer>
   );
};

export default StageFrontFullLogo;
