import { JSX, memo, useCallback, useEffect, useRef, useState } from "react";
import {
   CustomDropdownHolder,
   CustomDropdownIconHolder,
   CustomDropdownItemsHolder,
   CustomDropdownLabelHolder,
   CustomDropdownSingleItem,
   CustomDropdownTextHolder,
} from "./Dropdown.styled";

export type OptionsType = {
   id: number;
   label: string | JSX.Element;
   func?: () => void;
   dontClose?: boolean;
   hoverColor?: string;
   hoverBackground?: string;
   isSubItem?: boolean;
};

type DropdownTypes = {
   options: OptionsType[];
   label: JSX.Element | string;
   offset?: number;
   orientation?: string;
   fontSize?: string;
   textCenter?: boolean;
   icon?: JSX.Element;
   breakpoint?: number;
   headerColor?: string;
};

const Dropdown = ({
   options,
   label,
   headerColor,
   offset = 15,
   orientation = "right",
   fontSize = "16px",
   textCenter = false,
   breakpoint = 600,
   icon,
}: DropdownTypes) => {
   const [open, setOpen] = useState(false);
   const dropdownRef = useRef<HTMLDivElement>(null);
   const toggleOpen = () => setOpen(!open);

   const mappedOptions = options?.map((item: OptionsType) => {
      const { id, label, func, dontClose, hoverColor, hoverBackground, isSubItem } = item;
      return (
         <CustomDropdownSingleItem
            $isSubItem={isSubItem}
            $hoverColor={hoverColor}
            $hoverBackground={hoverBackground}
            onClick={() => {
               if (func) func();
               if (dontClose) return;
               toggleOpen();
            }}
            key={id}>
            {label}
         </CustomDropdownSingleItem>
      );
   });

   const handleOutsideClick = useCallback((event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
         setOpen(false);
      }
   }, []);

   useEffect(() => {
      if (open) {
         document.addEventListener("mousedown", handleOutsideClick);
      }
      return () => {
         document.removeEventListener("mousedown", handleOutsideClick);
      };
   }, [open, handleOutsideClick]);

   return (
      <CustomDropdownHolder $fontSize={fontSize} ref={dropdownRef}>
         <CustomDropdownLabelHolder onClick={toggleOpen}>
            {icon && <CustomDropdownIconHolder $breakpoint={breakpoint}>{icon ?? icon}</CustomDropdownIconHolder>}
            <CustomDropdownTextHolder $headerColor={headerColor} $hasIcon={!!icon} $breakpoint={breakpoint}>
               {label}
            </CustomDropdownTextHolder>
         </CustomDropdownLabelHolder>
         <CustomDropdownItemsHolder $open={open} $textCenter={textCenter} $orientation={orientation} $offset={offset}>
            {mappedOptions}
         </CustomDropdownItemsHolder>
      </CustomDropdownHolder>
   );
};

export default memo(Dropdown);
