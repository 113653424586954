import { IconContainer } from "./Icons.styled";

const SideBarIcon = () => {
   return (
      <IconContainer>
         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
               d="M7.4714 7.13807C7.73175 6.87772 7.73175 6.45561 7.4714 6.19526C7.21105 5.93491 6.78894 5.93491 6.5286 6.19526L3.19526 9.5286C2.93491 9.78894 2.93491 10.2111 3.19526 10.4714L6.5286 13.8047C6.78894 14.0651 7.21105 14.0651 7.4714 13.8047C7.73175 13.5444 7.73175 13.1223 7.4714 12.8619L4.60948 10L7.4714 7.13807Z"
               fill="#C0C0C0"
            />
            <path
               d="M12.8047 6.19526C12.5444 5.93491 12.1223 5.93491 11.8619 6.19526C11.6016 6.45561 11.6016 6.87772 11.8619 7.13807L14.7239 10L11.8619 12.8619C11.6016 13.1223 11.6016 13.5444 11.8619 13.8047C12.1223 14.0651 12.5444 14.0651 12.8047 13.8047L16.1381 10.4714C16.3984 10.2111 16.3984 9.78894 16.1381 9.5286L12.8047 6.19526Z"
               fill="#C0C0C0"
            />
         </svg>
      </IconContainer>
   );
};

export default SideBarIcon;
