import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { toast } from "sonner";
import { ResetPasswordType } from "../../../pages/resetPassword/ResetPassword";
import instance from "../../../service/instance";
import customCatch from "../../../utils/customCatch";
import { StoreStateType } from "../../app/store";
import { GlobalAuthorizationType } from "../../app/store.types";

const initialState: GlobalAuthorizationType = {
   isLoading: false,
   error: null,
};

export const userResetPassword = createAsyncThunk(
   "resetPassword/userResetPassword",
   async (payload: ResetPasswordType & { token: string }) => {
      const { newPassword, token } = payload;
      try {
         const response = await instance.post(`/user/reset-password?token=${token}`, { newPassword });
         toast.success(response.data?.message || "Password successfully changed");
      } catch (err) {
         customCatch(err as AxiosError);
      }
   },
);

const resetPasswordSlice = createSlice({
   name: "resetPassword",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(userResetPassword.pending, (state) => {
         return { ...state, isLoading: true, error: null };
      });
      builder.addCase(userResetPassword.fulfilled, (state) => {
         return { ...state, isLoading: false };
      });
      builder.addCase(userResetPassword.rejected, (state, action) => {
         return { ...state, isLoading: false, error: action.error.message as string };
      });
   },
});

export const resetPasswordSliceState = (state: StoreStateType) => state.resetPassword;

export default resetPasswordSlice.reducer;
