import { logout as lg } from "../components/header/HeaderDropdown";
import { toast } from "sonner";

const logout = () => {
   lg();
   toast.error("Your session has expired and you have been logged out.", {
      style: { color: "red" },
      closeButton: true,
      duration: 5000,
      id: "logout-toast",
   });
   localStorage.removeItem("automation_portal_token");
   localStorage.removeItem("automation_portal_refresh_token");
   localStorage.removeItem("automation_portal_token_expiration");
   localStorage.removeItem("automation_portal_refresh_token_expiration");
};

export default logout;
