import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { useCallback, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Toaster } from "sonner";
import { ThemeProvider } from "styled-components";
import FullPageLogo from "./components/fullPageLogo/FullPageLogo";
import GlobalStyle from "./components/globalStyles/GlobalStyle";
import Header from "./components/header/Header";
import Routes from "./routes/Routes";
import { StoreStateType } from "./store/app/store";
import { fetchUser, userSliceState } from "./store/features/user/userSlice";
import themes from "./themes/themes";

const App = () => {
   const [initialLoad, setInitialLoad] = useState(true);
   const reduxTheme = useSelector((state: StoreStateType) => state.theme);
   const { token, rememberMe } = useSelector((state: StoreStateType) => state.token);
   const { isLoading } = useSelector(userSliceState);

   const dispatch = useDispatch<ThunkDispatch<StoreStateType, any, AnyAction>>();

   const unloadToken = useCallback(
      () => !rememberMe && localStorage.removeItem("automation_portal_token"),
      [rememberMe],
   );

   const appLoading = isLoading || initialLoad;

   useLayoutEffect(() => {
      token && dispatch(fetchUser());
      initialLoad && setInitialLoad(false);

      window.addEventListener("beforeunload", unloadToken);
      return () => window.removeEventListener("beforeinput", unloadToken);
   }, [dispatch, token, unloadToken, initialLoad]);

   return (
      <ThemeProvider theme={themes[reduxTheme] || themes["Light"]}>
         <GlobalStyle />
         <FullPageLogo isSplash show={isLoading} />
         {!appLoading && <Header />}
         {!appLoading && <Routes />}
         <Toaster
            toastOptions={{
               style: { background: "white", padding: "10px" },
               className: "my-toast",
            }}
            duration={4000}
            position="top-center"
            expand={false}
         />{" "}
      </ThemeProvider>
   );
};

export default App;
