import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { toast } from "sonner";
import { ChangePasswordSectionTypes } from "../../../pages/settings/sections/ChangePasswordSection";
import instance from "../../../service/instance";
import customCatch from "../../../utils/customCatch";
import { StoreStateType } from "../../app/store";
import { UserColumnSettingsType, UserSettingsType } from "./userSlice";

export type UserUpdatesType = {
   firstName?: string;
   lastName?: string;
   settings?: UserSettingsType;
   columnSettings?: UserColumnSettingsType;
};

const initialState = {};

export const updateMe = createAsyncThunk("userUpdates/me", async (payload: UserUpdatesType) => {
   try {
      const { data } = await instance.patch("/user/me", payload);
      toast.success(data?.message || "Settings saved successfully");
      return data?.data?.user;
   } catch (err) {
      customCatch(err as AxiosError);
   }
});

export const changePassword = createAsyncThunk(
   "userUpdates/changePassword",
   async (payload: ChangePasswordSectionTypes) => {
      try {
         const { data } = await instance.patch("/user/me/changePassword", payload);
         toast.success(data?.message || "Settings saved successfully");
         return data?.data?.user;
      } catch (err) {
         customCatch(err as AxiosError);
      }
   },
);

const userUpdatesSlice = createSlice({
   name: "userUpdates",
   initialState,
   reducers: {},
});

export const userUpdatesSliceState = (state: StoreStateType) => state.userUpdates;

export default userUpdatesSlice.reducer;
