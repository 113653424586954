import styled from "styled-components";

export const HeaderContainer = styled.header`
   position: sticky;
   top: 0;
   display: flex;
   justify-content: space-between;
   background-color: BLACK;
   padding: 12px 30px;
   flex-wrap: wrap;
   z-index: 999;

   & > * {
      display: flex;
      align-items: center;
   }

   @media only screen and (max-width: ${({ theme: { breakpointS } }) => breakpointS}) {
      padding: 12px 10px;
   }
`;

export const HeaderContainerLeft = styled.div``;
export const HeaderContainerRight = styled.div``;

export const HeaderIconHolder = styled.div`
   height: 35px;
   width: 150px;
   gap: 7px;
   display: flex;
   justify-items: flex-start;
   align-items: center;
   cursor: pointer;

   & div {
      width: 100px;
   }
`;

export const DropdownItemHolder = styled.div`
   display: flex;
   gap: 10px;
   align-items: center;
`;
