import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../service/instance";

export interface Company {
   companyId: number;
   name: string;
   email: string;
}

export interface CompanyState {
   companies: Company[];
   loading: boolean;
   error: string | null;
}

const initialState: CompanyState = {
   companies: [],
   loading: false,
   error: null,
};

let cachedData: Company[] | null = null;
let cacheExpiration: number = 0;
const CACHE_DURATION = 5 * 60 * 1000; // Cache duration in milliseconds (e.g., 5 minutes)

export const fetchCompanies = createAsyncThunk("companies/fetchCompanies", async () => {
   const url = `${process.env.REACT_APP_API_BASE_URL}/Companies/GetCompanies`;
   const currentTime = new Date().getTime();

   if (cachedData && currentTime < cacheExpiration) {
      return cachedData;
   }

   const storedCompanies = sessionStorage.getItem("companies");
   if (storedCompanies) {
      const parsedCompanies = JSON.parse(storedCompanies);
      cachedData = parsedCompanies;
      cacheExpiration = currentTime + CACHE_DURATION;
      return parsedCompanies;
   }

   try {
      const { data } = await instance.get<Company[]>(url);
      cachedData = data;
      cacheExpiration = currentTime + CACHE_DURATION;
      sessionStorage.setItem("companies", JSON.stringify(data));
      return data;
   } catch (error: any) {
      throw new Error(error.response?.data?.error || error.message || JSON.stringify(error));
   }
});

const companySlice = createSlice({
   name: "companies",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder
         .addCase(fetchCompanies.pending, (state) => {
            state.loading = true;
            state.error = null;
         })
         .addCase(fetchCompanies.fulfilled, (state, action: PayloadAction<Company[]>) => {
            state.loading = false;
            state.companies = action.payload;
         })
         .addCase(fetchCompanies.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message || "Failed to fetch companies";
         });
   },
});

export default companySlice.reducer;
