import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ForgotPasswordSubmitType } from "../../../pages/forgotPassword/ForgotPassword";
import instance from "../../../service/instance";
import customCatch from "../../../utils/customCatch";
import { StoreStateType } from "../../app/store";
import { GlobalAuthorizationType } from "../../app/store.types";

const initialState: GlobalAuthorizationType = {
   isLoading: false,
   error: null,
};

export const userForgotPassword = createAsyncThunk(
   "forgotPassword/userForgotPassword",
   async (payload: ForgotPasswordSubmitType) => {
      try {
         await instance.post("/user/forgot-password", { ...payload });
      } catch (err) {
         customCatch(err as AxiosError);
      }
   },
);

const forgotPasswordSlice = createSlice({
   name: "forgotPassword",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(userForgotPassword.pending, (state) => {
         return { ...state, isLoading: true, error: null };
      });
      builder.addCase(userForgotPassword.fulfilled, (state) => {
         return { ...state, isLoading: false };
      });
      builder.addCase(userForgotPassword.rejected, (state, action) => {
         return { ...state, isLoading: false, error: action.error.message as string };
      });
   },
});

export const forgotPasswordSliceState = (state: StoreStateType) => state.forgotPassword;

export default forgotPasswordSlice.reducer;
