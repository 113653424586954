export const MAHOGANY_RED = "#BE3636";
export const VENETIAN_RED = "#C91A1A";
export const LIVER = "#6F3013";
export const ALUMINIUM = "#8A8C91";
export const EIGENGRAU = "#161A23";
export const DEEP_CARROT_ORANGE = "#EC6728";
export const WHITE = "#FFFFFF";
export const LOTION = "#FAFAFA";
export const BLACK_OLIVE = "#3C3C3C";
export const WHITE_SMOKE = "#F3F3F3";

export const CHARLESTON_GREEN = "#292D32";
export const QUICK_SILVER_05 = "rgba(160, 159, 167, 0.5)";
export const GAINSBORO = "#dddfe1";
export const GRANITE_GRAY = "#5C5F65";
export const WATER = "#D9EAFF";
export const MINT = "#BAEBD9";
export const TEA_GREEN = "#CEEFC6";

export const BTN_PRIMARY_COLOR = "#EC6728";
export const BTN_SECONDARY_COLOR = "#1E1E1E";
export const BACKGROUND = "#EEEFF2";
export const INPUT_BACKGROUND = "#fbfbfb";
export const INPUT_BORDER_COLOR = "#eeeff2";
export const SECTION = "#FFF";

export const textSecondary = "#3C3C3C";
export const statisticsText = "#969BA0";
export const textAlternative = "#969BA0";
export const statisticsTextColor = "#000000";

export const TABLE_STATUS_PENDING = "rgba(239, 189, 62, 0.2)";
export const TABLE_STATUS_DELAYED = "rgba(168, 168, 168, 0.3)";
export const TABLE_STATUS_FAILED = "rgba(236, 103, 40, 0.2)";

export const tableBorder = "#DDDFE1";

export const EERIE_BLACK = "#1C1B17";
export const BLACK = "#000000";
export const COLADON_BLUE = "#0178AE";
export const ANTI_FLASH_WHITE = "#F3F3F3";
export const BRIGHT_GRAY = "#E9E9E9";
export const BRIGHT_GRAY_ALPHA = "#e9ebf1";
export const PLATINUM = "#E4E4E4";
export const AMERICAN_SILVER = "#D1D1D1";
export const GLAUCOUS = "#627AC0";
export const SILVER_SAND = "rgba(60, 60, 60, 0.5)";
export const SPANISH_GRAY = "#969BA0";
export const DARK_JUNGLE_GREEN = "#152426";
export const DARK_SEA_GREEN = "#8FBB84";
export const SONIC_SILVER = "#757575";
export const CULTURED = "#F5F5F5";
export const LIGHT_SILVER = "#D6D6D6";
export const QUICK_SILVER = "#A09FA7";
export const GAINSBORO_ALPHA = "#dddddd";
export const OUTER_SPACE = "#464646";
export const CHINESE_BROWN = "#B83519";
export const GRAY_X11 = "#BABABA";
export const SILVER_CHALICE = "#ABABAB";
export const ISLAMIC_GREEN = "#048d00";
export const KELLY_GREEN = "#5fb400";
export const LIMERICK = "#a6c500";
export const GOLDEN_POPPY = "#f3c305";
export const APPLE = "#83b643";
export const CADMIUM_ORANGE = "#eb8d35";
export const SINOPIA = "#da480f";
export const CARNELIAN = "#b32512";
export const CINNABAR = "#ea3c31";
export const CHINESE_WHITE = "#E0E0E0";
export const CORAL_RED = "#FE3939";
export const CHINESE_SILVER = "#cac8c8";
export const OXLEY = "#7A9B72";
export const LUST = "#E1261A";
export const APPLE_GREEN = "#83B643";
export const ATHENS_GRAY = "#EEEFF2";
export const PALE_LAVANDER = "#E1CEF9";
export const LIGHT_GOLDENROD_YELLOW = "#FFF8D3";
export const BABY_PINK = "#F3C7C7";
export const ARGENT = "#C1C1C1";
export const BLACK_087 = "rgba(0, 0, 0, 0.87)";
export const DARK_CHARCOAL = "#333333";
export const CELADON_BLUE = "#097EAF";
export const MANGO_TANGO = "#FF8A3C";
export const RED = "#FF0000";
