import styled from "styled-components";

type IconContainerType = {
   $isDisabled?: boolean;
};

export const IconContainer = styled.div<IconContainerType>`
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: ${({ $isDisabled }) => ($isDisabled ? "not-allowed" : "pointer")};
   transition: 300ms;

   &:hover {
      transform: scale(0.9);
   }
`;
