import styled from "styled-components";

export const LayoutContainer = styled.div`
   flex-grow: 1;
   display: flex;
   flex-direction: row;
   position: relative;
`;

export const LayoutPageLeft = styled.div<{ $isShrank: boolean }>`
   align-self: stretch;
   background-color: black;
   width: ${({ $isShrank }) => ($isShrank ? "90px" : "250px")};
   flex-shrink: 0;
   transition: 200ms;

   @media only screen and (max-width: ${({ theme: { breakpointL } }) => breakpointL}) {
      display: none;
   }
`;

export const LayoutPageRight = styled.div`
   display: flex;
   flex-direction: column;
   padding: 15px;
   flex-grow: 1;
   gap: 15px;
   overflow: hidden;

   @media only screen and (max-width: ${({ theme: { breakpointL } }) => breakpointL}) {
      max-width: 100%;
   }

   @media only screen and (max-width: ${({ theme: { breakpointS } }) => breakpointS}) {
      padding: 0px;
   }
`;
