import React from "react";
import { IconContainer } from "./Icons.styled";
import { ALUMINIUM } from "../../constants/color";

type FullscreenOpenIconType = {
   width?: number;
   height?: number;
};

const FullscreenOpen = ({ width, height }: FullscreenOpenIconType) => {
   return (
      <IconContainer>
         <svg width={width} height={height} viewBox="0 0 448 512">
            <path
               fill={ALUMINIUM}
               d="M32 32C14.3 32 0 46.3 0 64v96c0 17.7 14.3 32 32 32s32-14.3 32-32V96h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H32zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7 14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H64V352zM320 32c-17.7 0-32 14.3-32 32s14.3 32 32 32h64v64c0 17.7 14.3 32 32 32s32-14.3 32-32V64c0-17.7-14.3-32-32-32H320zM448 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64H320c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32V352z"
            />
         </svg>
      </IconContainer>
   );
};

export default FullscreenOpen;
