import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
	*,
	*::after,
	*::before {	
		margin: 0;
		padding: 0;
		box-sizing: border-box;
   	font-family: 'Montserrat', sans-serif;
		-webkit-tap-highlight-color: transparent;
	}

	::selection {
		background-color: #EC6728;
		color: ${({ theme: { text } }) => text};
	}

	input[type=number] { 
    	-moz-appearance: textfield;
    	appearance: textfield;
   	 margin: 0; 
	}

	input[type=number]::-webkit-inner-spin-button,
	input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
	}

	#root {
		display:flex;
		flex-direction:column;
		min-height:100vh;
	}
	
	body {
		background-color: ${({ theme: { background } }) => background};
		color: ${({ theme: { text } }) => text};
	}
	
	img {
		max-width: 100%;
	}

	::-webkit-scrollbar {
	  width: 8px;
	  height: 8px;
	}

	::-webkit-scrollbar-track {
	  background: transparent;
	}

	::-webkit-scrollbar-thumb {
	  background: #888;
	  border-radius:10px;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #999;
	}

	@keyframes hopInFromBottom {
		0% { 
			opacity: 0;
			transform:translateY(10px);
		} 40% { 
			opacity: 1; 
		} 100% {
			transform:translateY(0px);
		}
	}
	
	@keyframes hopInFromTop {
		0% { 
			opacity: 1;
			transform:translateY(-100px);
		} 40% { 
			opacity: 1; 
		} 100% {
			transform:translateY(0px);
		}
	}

	@keyframes opacityFadeIn {
		0% { 
			opacity: 0;
		} 100% {
			opacity: 1; 
		}
	}

	@keyframes opacityFadeOut {
		0% { 
			display:flex;
			opacity: 1;
		} 60% {
			opacity:1
		}  100% {
			display:none;
			opacity: 0; 
		}
	}

	@keyframes counterClockwiseRotation {
		0% { 
			transform: rotate(0deg)
		} 100% {
			transform: rotate(-360deg)
		}
	}
`;

export default GlobalStyle;
