import React from "react";
import styled from "styled-components";

const BurgerIconHolder = styled.div`
   display: flex;
   align-items: center;
   transition: 200ms;

   &:hover {
      opacity: 0.9;
      transform: scale(0.95);
   }
`;
const BurgerIcon = () => {
   return (
      <BurgerIconHolder>
         <svg xmlns="http://www.w3.org/2000/svg" width="22" viewBox="0 0 32 22" fill="none">
            <path
               d="M32 20.4286C32 20.0118 31.8595 19.6121 31.6095 19.3174C31.3594 19.0227 31.0203 18.8571 30.6667 18.8571H1.33333C1 19 0.640573 19.2053 0.390525 19.5C0.140476 19.7947 0 20.0832 0 20.5C0 20.9168 0.140476 21.2053 0.390525 21.5C0.640573 21.7947 0.979711 22 1.33333 22H30.6667C31.0203 22 31.3594 21.8344 31.6095 21.5397C31.8595 21.245 32 20.8453 32 20.4286ZM32 11C32 10.5832 31.8595 10.1835 31.6095 9.88883C31.3594 9.59413 31.0203 9.42857 30.6667 9.42857H1.33333C0.979711 9.42857 0.640573 9.59413 0.390525 9.88883C0.140476 10.1835 0 10.5832 0 11C0 11.4168 0.140476 11.8165 0.390525 12.1112C0.640573 12.4059 0.979711 12.5714 1.33333 12.5714H30.6667C31.0203 12.5714 31.3594 12.4059 31.6095 12.1112C31.8595 11.8165 32 11.4168 32 11ZM32 1.57143C32 1.15466 31.8595 0.754961 31.6095 0.460261C31.3594 0.165561 31.0203 0 30.6667 0H1.33333C0.979711 0 0.640573 0.165561 0.390525 0.460261C0.140476 0.754961 0 1.15466 0 1.57143C0 1.9882 0.140476 2.3879 0.390525 2.6826C0.640573 2.9773 0.979711 3.14286 1.33333 3.14286H30.6667C31.0203 3.14286 31.3594 2.9773 31.6095 2.6826C31.8595 2.3879 32 1.9882 32 1.57143Z"
               fill="white"
            />
         </svg>
      </BurgerIconHolder>
   );
};
export default BurgerIcon;
