import { BeatLoader } from "react-spinners";
import { LoaderContainer, MessageContainer } from "./Loader.styled";
import { QUICK_SILVER_05 } from "../../constants/color";

type LoaderType = {
   isFullPage?: boolean;
   minHeight?: number;
   hasBg?: boolean;
   isTransparent?: boolean;
   message?: string;
   isOverlay?: boolean;
};

const Loader = ({ isFullPage = false, minHeight, hasBg = false, isTransparent, message, isOverlay }: LoaderType) => {
   return (
      <>
         <LoaderContainer
            $hasBg={hasBg}
            $minHeight={minHeight}
            $isFullPage={isFullPage}
            $isTransparent={isTransparent}
            $isOverlay={isOverlay}>
            <BeatLoader color={isTransparent ? "black" : QUICK_SILVER_05} />
            {message && <MessageContainer>{message}</MessageContainer>}
         </LoaderContainer>
      </>
   );
};

export default Loader;
