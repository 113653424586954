import { useLocation, useNavigate } from "react-router-dom";
import StageFrontLogo from "../../assets/icons/StageFrontLogo";
import StageFrontText from "../../assets/icons/StageFrontText";
import paths from "../../routes/paths";
import { HeaderContainer, HeaderContainerLeft, HeaderContainerRight, HeaderIconHolder } from "./Header.styled";
import HeaderDropdown from "./HeaderDropdown";

const Header = () => {
   const { pathname } = useLocation();
   const navigate = useNavigate();

   const navigateToFulfillment = () => navigate(paths.fulfillment);

   const canShowHeader =
      pathname !== paths.login && pathname !== paths.forgotPassword && pathname !== paths.resetPassword;

   if (!canShowHeader) return null;
   return (
      <HeaderContainer>
         <HeaderContainerLeft>
            <HeaderIconHolder onClick={navigateToFulfillment}>
               <StageFrontLogo />
               <StageFrontText gap={5} />
            </HeaderIconHolder>
         </HeaderContainerLeft>
         <HeaderContainerRight>
            <HeaderDropdown />
         </HeaderContainerRight>
      </HeaderContainer>
   );
};

export default Header;
