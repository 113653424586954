import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FulfillmentFiltersState {
   params: Record<string, string | undefined>;
}

const initialState: FulfillmentFiltersState = {
   params: {},
};

const fulfillmentFiltersSlice = createSlice({
   name: "fulfillmentFilters",
   initialState,
   reducers: {
      setFulfillmentFilters(state, action: PayloadAction<Record<string, string | undefined>>) {
         state.params = action.payload;
      },
      resetFulfillmentFilters(state) {
         state.params = {};
      },
   },
});

export const { setFulfillmentFilters, resetFulfillmentFilters } = fulfillmentFiltersSlice.actions;
export default fulfillmentFiltersSlice.reducer;
