import { JSX } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { userSliceState } from "../store/features/user/userSlice";
import paths from "./paths";

export type RouteTypes = {
   children: JSX.Element;
};

const ProtectedRoute = ({ children }: RouteTypes) => {
   if (localStorage.getItem("automation_portal_token")) {
      return children;
   }
   return <Navigate to={paths.login} />;
};

export default ProtectedRoute;
