import { Navigate } from "react-router-dom";
import { RouteTypes } from "./ProtectedRoute";
import paths from "./paths";

const LoginRoute = ({ children }: RouteTypes) => {
   if (localStorage.getItem("automation_portal_token")) {
      return <Navigate to={paths.fulfillment} />;
   }
   return children;
};

export default LoginRoute;
