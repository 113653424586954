import styled, { css } from "styled-components";

type CustomDropdownTypes = {
   $fontSize: string;
   $hoverColor: string | undefined;
   $hoverBackground: string | undefined;
   $open: boolean;
   $textCenter: boolean;
   $orientation: string;
   $offset: number;
   $hasIcon: boolean;
   $breakpoint: number;
   $headerColor: undefined | string;
   $isSubItem: undefined | boolean;
};

export const CustomDropdownHolder = styled.div<Pick<CustomDropdownTypes, "$fontSize">>`
   position: relative;
   color: ${({ theme: { text } }) => text};
   font-size: ${(props) => props.$fontSize};
   user-select: none;
`;

export const CustomDropdownSingleItem = styled.div<
   Pick<CustomDropdownTypes, "$hoverBackground" | "$hoverColor" | "$isSubItem">
>`
   width: max-content;
   padding: ${({ $isSubItem }) => ($isSubItem ? "15px 20px 15px 50px" : "15px 20px")};
   cursor: pointer;
   color: ${({ theme: { text } }) => text};
   position: relative;
   z-index: 10;
   min-width: 100%;
   transition: 100ms;
   font-size: 14px;
   border-top: 1px solid whitesmoke;

   &:hover {
      background-color: ${({ $hoverBackground, theme: { dropdownHoverColor } }) =>
         $hoverBackground ?? dropdownHoverColor};
      color: ${({ $hoverColor }) => $hoverColor};
   }

   &:first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
   }

   &:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
   }
`;

export const CustomDropdownLabelHolder = styled.div`
   display: flex;
   gap: 5px;
   cursor: pointer;
   color: white;
   transition: 300ms;

   &:hover {
      opacity: 0.85;
   }
`;

export const CustomDropdownIconHolder = styled.div<Pick<CustomDropdownTypes, "$breakpoint">>`
   display: flex;
   align-items: center;
   justify-content: center;

   @media only screen and (min-width: ${({ $breakpoint }) => `${$breakpoint}px`}) {
      display: none;
   }
`;

export const CustomDropdownTextHolder = styled.div<
   Pick<CustomDropdownTypes, "$hasIcon" | "$breakpoint" | "$headerColor">
>`
   color: ${({ $headerColor, theme: { text } }) => $headerColor ?? text};
   ${({ $hasIcon, $breakpoint }) =>
      $hasIcon &&
      css`
         @media only screen and (max-width: ${`${$breakpoint}px`}) {
            display: none;
         }
      `};
`;

export const CustomDropdownItemsHolder = styled.div<
   Pick<CustomDropdownTypes, "$open" | "$orientation" | "$offset" | "$textCenter">
>`
   display: ${({ $open }) => ($open ? "block" : "none")};
   position: absolute;
   ${({ $orientation }) => ($orientation === "right" ? "right: 0%;" : "left: 0%;")}
   background-color: ${({ theme: { section } }) => section};
   top: ${({ $offset }) => `calc(100% + ${$offset}px)`};
   border-radius: 6px;
   box-shadow: rgba(0, 0, 0, 0.2) 0 0 6px;
   z-index: 20;
   cursor: pointer;
   color: white;
   text-align: ${({ $textCenter }) => ($textCenter ? "center" : "start")};
   animation: hopInFromBottom 200ms ease-in-out;
`;
