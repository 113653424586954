import { memo, useState } from "react";
import { Outlet } from "react-router-dom";
import SideBar from "../../components/sideBar/SideBar";
import { LayoutContainer, LayoutPageLeft, LayoutPageRight } from "./Layout.styled";

const Layout = () => {
   const [collapsed, setCollapsed] = useState(false);
   const toggleCollapsed = () => setCollapsed(!collapsed);
   return (
      <LayoutContainer>
         <LayoutPageLeft $isShrank={collapsed}>
            <SideBar collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
         </LayoutPageLeft>
         <LayoutPageRight>
            <Outlet />
         </LayoutPageRight>
      </LayoutContainer>
   );
};

export default memo(Layout);
