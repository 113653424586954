import StageFrontFullLogo from "../../assets/icons/StageFrontFullLogo";
import {
   FullPageLogoContainer,
   StageFrontHeadline,
   StageFrontParagraph,
   StageFrontTextHolder,
} from "./FullPageLogo.styled";

type FullPageLogoType = {
   show: boolean;
   isSplash?: boolean;
};

const FullPageLogo = ({ show, isSplash = false }: FullPageLogoType) => {
   return (
      <FullPageLogoContainer $show={show}>
         <StageFrontFullLogo isSplash={isSplash} interaction={false} />
         <StageFrontTextHolder>
            <StageFrontHeadline>Streamline Your Success</StageFrontHeadline>
            <StageFrontParagraph>
               Revolutionize Your Buying Journey and Fuel Your POS with Unmatched Efficiency!
            </StageFrontParagraph>
         </StageFrontTextHolder>
      </FullPageLogoContainer>
   );
};

export default FullPageLogo;
