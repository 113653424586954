import styled, { css } from "styled-components";
import { ALUMINIUM, EIGENGRAU, GRANITE_GRAY, LIVER, WHITE } from "../../constants/color";

export const SideBarContainer = styled.div`
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   padding: 35px 20px;
   gap: 20px;
   position: relative;
`;

interface SideBarProps {
   $active?: boolean;
}

export const SideBarItem = styled.div<SideBarProps>`
   display: flex;
   gap: 35px;
   align-items: center;
   overflow: hidden;
   transition: 200ms;
   padding: 10px 12px 10px 12px;
   border-radius: 8px;
   user-select: none;

   &:hover {
      cursor: pointer;
      scale: 0.99;
   }

   ${({ $active }) =>
      $active &&
      css`
         background-color: ${LIVER};
         color: ${WHITE};
      `}
`;

export const SideBarIconHolder = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
`;

export const SideBarTextHolder = styled.div<SideBarProps>`
   text-align: center;
   color: ${ALUMINIUM};
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: normal;
   white-space: nowrap;
   text-overflow: ellipsis;

   ${({ $active }) =>
      $active &&
      css`
         color: ${WHITE} !important;
      `}
`;

export const SideBarToggleButton = styled.div<{ $collapsed: boolean | undefined }>`
   position: absolute;
   top: 8px;
   right: -14px;
   background-color: ${EIGENGRAU};
   border-radius: 8px;
   border: 1px solid ${GRANITE_GRAY};
   padding: 4px;
   color: ${WHITE};
   cursor: pointer;
   display: flex;
   align-items: center;
   justify-content: center;
`;

export const LogoLine = styled.div`
   height: 1px;
   background-color: ${GRANITE_GRAY};
`;
