import { IconContainer } from "./Icons.styled";
import React from "react";
import { ALUMINIUM } from "../../constants/color";

type FullscreenCloseIconType = {
   width?: number;
   height?: number;
};

const FullscreenClose = ({ width, height }: FullscreenCloseIconType) => {
   return (
      <IconContainer>
         <svg width={width} height={height} viewBox="0 0 448 512">
            <path
               fill={ALUMINIUM}
               d="M160 64c0-17.7-14.3-32-32-32s-32 14.3-32 32v64H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32V64zM32 320c-17.7 0-32 14.3-32 32s14.3 32 32 32H96v64c0 17.7 14.3 32 32 32s32-14.3 32-32V352c0-17.7-14.3-32-32-32H32zM352 64c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7 14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H352V64zM320 320c-17.7 0-32 14.3-32 32v96c0 17.7 14.3 32 32 32s32-14.3 32-32V384h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H320z"
            />
         </svg>
      </IconContainer>
   );
};

export default FullscreenClose;
