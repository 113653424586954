import React from "react";
import styled from "styled-components";
import ArrowDownIcon from "../../assets/icons/ArrowDownIcon";

const StyledHeaderDropdownLabel = styled.div`
   display: flex;
   align-items: center;
   gap: 10px;
`;

const HeaderDropdownLabel = () => {
   return (
      <StyledHeaderDropdownLabel>
         My Profile <ArrowDownIcon fill="white" />
      </StyledHeaderDropdownLabel>
   );
};

export default HeaderDropdownLabel;
