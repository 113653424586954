const paths = Object.freeze({
   login: "/login",
   forgotPassword: "/forgot-password",
   resetPassword: "/reset-password",
   dashboard: "/dashboard",
   fulfillment: "/fulfillment",
   invoice: "/invoice/:id",
   onboarding: "/onboarding",
   catchAll: "*",
});

export default paths;
