import { IconContainer } from "./Icons.styled";
import { ALUMINIUM } from "../../constants/color";
import { Icon } from "@iconify/react";

type OnboardingIconType = {
   isActive?: boolean;
   width?: number;
   height?: number;
};

const OnboardingIcon = ({ isActive, width = 23, height = 27 }: OnboardingIconType) => {
   return (
      <IconContainer>
         <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 2048 2048">
            <path
               fill={isActive ? "white" : ALUMINIUM}
               d="M2048 512v1536H0V512h517q-2-16-3-32t-2-32q0-93 35-174t96-143t142-96T960 0q93 0 174 35t143 96t96 142t35 175q0 16-1 32t-4 32zM960 128q-66 0-124 25t-102 69t-69 102t-25 124t25 124t68 102t102 69t125 25t124-25t101-68t69-102t26-125t-25-124t-69-101t-102-69t-124-26m960 512h-555q-25 52-62 97t-85 77q103 40 186 106t140 152t89 188t31 212v64h-128v-64q0-123-44-228t-121-183t-182-121t-229-44q-111 0-210 38t-176 107t-126 162t-61 205h648l-230-230l91-90l384 384l-384 384l-91-90l230-230H256v-64q0-110 31-211t90-187t141-152t185-107q-98-69-148-175H128v1280h1792z"
            />
         </svg>
      </IconContainer>
   );
};

export default OnboardingIcon;
