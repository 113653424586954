import styled, { css } from "styled-components";

export const FullPageLogoContainer = styled.div<{ $show: boolean }>`
   position: fixed;
   inset: 0;
   z-index: 1000;
   padding: 20px;
   min-height: 100vh;
   background-color: black;
   display: ${({ $show }) => ($show ? "flex" : "none")};
   flex-direction: column;
   gap: 40px;
   align-items: center;
   justify-content: center;
   color: white;

   ${({ $show }) =>
      !$show &&
      css`
         animation: opacityFadeOut 1000ms ease-in-out;
      `}
`;

export const StageFrontTextHolder = styled.div`
   width: 450px;
   max-width: 100%;
   display: flex;
   flex-direction: column;
   gap: 10px;
`;

export const StageFrontHeadline = styled.div`
   color: #fff;
   text-align: center;
   font-size: 22px;
   font-weight: 500;

   @media only screen and (max-width: ${({ theme: { breakpointM } }) => breakpointM}) {
      font-size: 16px;
   }
`;
export const StageFrontParagraph = styled.div`
   color: #a3a3a3;
   text-align: center;
   font-size: 14px;
   font-weight: 400;
   line-height: 27px;

   @media only screen and (max-width: ${({ theme: { breakpointM } }) => breakpointM}) {
      font-size: 11px;
   }
`;
