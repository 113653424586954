import { useTheme } from "styled-components";
import { IconContainer } from "./Icons.styled";

type ArrowDownIconType = {
   fill?: string;
};

const ArrowDownIcon = ({ fill }: ArrowDownIconType) => {
   const { text } = useTheme();

   return (
      <IconContainer>
         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
               fillRule="evenodd"
               clipRule="evenodd"
               d="M7.29289 9.29289C7.68342 8.90237 8.31658 8.90237 8.70711 9.29289L12 12.5858L15.2929 9.29289C15.6834 8.90237 16.3166 8.90237 16.7071 9.29289C17.0976 9.68342 17.0976 10.3166 16.7071 10.7071L12.7071 14.7071C12.5196 14.8946 12.2652 15 12 15C11.7348 15 11.4804 14.8946 11.2929 14.7071L7.29289 10.7071C6.90237 10.3166 6.90237 9.68342 7.29289 9.29289Z"
               fill={fill ?? text}
            />
         </svg>
      </IconContainer>
   );
};

export default ArrowDownIcon;
