import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GlobalAuthorizationType } from "../../app/store.types";
import instance, { automationInstance } from "../../../service/instance";
import { StoreStateType } from "../../app/store";
import { FulfillmentData } from "../../../pages/fulfillment/FulfillmentTable/FulfillmentTable.constants";
import customCatch from "../../../utils/customCatch";

// overall statistics:
export type StatisticsIndividualType = {
   email: number;
   invoiceFulfilled: number;
   thanLastWeek: number;
};
export type StatisticsSliceType = {
   [key: string]: StatisticsIndividualType;
};

export type InvoiceSliceType = {
   ticketsNow_Production_Id: number;
   tm_Event_ID: string;
   transfer_Id: number;
   status: string;
   event_Id: number;
   invoice_Id: number;
   purch_Date: string;
   pO_ID: number;
   buyer_Po_Id: string;
   company: string;
   cust_Id: number;
   event_Headliner: string;
   event_Name: string;
   event_Date: string;
   event_Time: string;
   venue_Name: string;
   section: string;
   row: string;
   seat_From: number;
   seat_Thru: number;
   quantity: number;
   invoice_Amount: number;
   internal_Notes: string;
   external_Notes: string;
   confirmation_No: string;
   shipping_Comments: string;
   company_Name: string;
   listing_ID: number;
   exchange_Name: string;
   broker_email: string;
   marketplace: string;
   automation_Note: string;
   buyer: string;
   buyers_email: string;
   tickets_Available_Date: string;
   transfer_Url: string;
};

export type FulfillmentType = {
   statistics: StatisticsSliceType;
   invoices: InvoiceSliceType[];
};

export type FulfillmentSliceTypes = GlobalAuthorizationType & {
   data: FulfillmentType | null;
};

const initialState: FulfillmentSliceTypes = {
   isLoading: false,
   data: null,
   error: null,
};

interface FetchInvoicesParams {
   companyName: string;
   brokerEmail: string;
   daysUntilEvent: number | null;
   invoiceId: string;
   inPasswordManager?: boolean;
   onboardedAccount?: boolean;
   status?: string;
}

const parseComments = (shippingComments: string | null, internalNotes: string | null) => {
   const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;

   let buyers_email = null;
   if (shippingComments) {
      const emailMatch = shippingComments.match(emailRegex);
      buyers_email = emailMatch ? emailMatch[0] : null;
   }

   let buyer = null;
   let marketplace = null;
   if (shippingComments) {
      const firstPipeIndex = shippingComments.indexOf("|");
      if (firstPipeIndex !== -1) {
         const namePart = shippingComments.substring(0, firstPipeIndex).trim();
         const nameRegex = /- ([^-]+) - ([^-]+)$/;
         const nameMatch = namePart.match(nameRegex);
         buyer = nameMatch ? `${nameMatch[1]} ${nameMatch[2]}` : null;

         const marketplacePart = shippingComments.substring(firstPipeIndex + 1).trim();
         const marketplaceRegex = /^([^|]+) Order #/;
         const marketplaceMatch = marketplacePart.match(marketplaceRegex);
         marketplace = marketplaceMatch ? marketplaceMatch[1].trim() : null;
      }
   }

   let broker_email = null;
   if (internalNotes) {
      const brokerEmailMatch = internalNotes.match(emailRegex);
      broker_email = brokerEmailMatch ? brokerEmailMatch[0] : null;
   }

   return { buyers_email, buyer, marketplace, broker_email };
};

export const fetchHistory = createAsyncThunk(
   "fulfillment/fetchHistory",
   async (invoiceId: string, { rejectWithValue }) => {
      const url = `/Transfer/TransferHistory?invoiceId=${invoiceId}`;
      try {
         const { data } = await instance.get(url);
         return data;
      } catch (err: any) {
         rejectWithValue(err.response?.data?.error);
      }
   },
);

// Cache object and previous cache key
const cache: { [key: string]: any } = {};
let previousCacheKey = "";

export const fetchInvoices = createAsyncThunk(
   "fulfillment/fetchInvoices",
   async (params: FetchInvoicesParams, { rejectWithValue }) => {
      const { companyName, brokerEmail, daysUntilEvent, invoiceId, inPasswordManager, onboardedAccount, status } =
         params;

      const queryParams = new URLSearchParams({
         companyName,
         brokerEmail,
         daysUntilEvent: daysUntilEvent ? daysUntilEvent.toString() : "",
         invoiceId,
      });

      if (inPasswordManager !== undefined) {
         queryParams.append("inPasswordManager", inPasswordManager.toString());
      }

      if (onboardedAccount !== undefined) {
         queryParams.append("onboardedAccount", onboardedAccount.toString());
      }

      if (status === "fulfilled") {
         queryParams.append("status", "fulfilled");
      }

      const url = `/Transfer/GetInvoiceForTransfer?${queryParams.toString()}`;

      // Create a cache key
      const cacheKey = JSON.stringify({
         companyName,
         brokerEmail,
         daysUntilEvent,
         invoiceId,
         inPasswordManager,
         onboardedAccount,
         status,
      });

      // Check cache
      if (cache[cacheKey] && cacheKey === previousCacheKey) {
         return cache[cacheKey];
      }

      try {
         const { data } = await instance.get(url);

         const processedData = data.map((item: FulfillmentData) => {
            const { buyers_email, buyer, marketplace, broker_email } = parseComments(
               item.shipping_Comments,
               item.internal_Notes,
            );
            const event_Date = item.event_Date ? item.event_Date.substring(0, 10) : null;
            const tickets_Available_Date = item.tickets_Available_Date
               ? item.tickets_Available_Date.substring(0, 10)
               : null;

            const status = item.status || "";

            const seats =
               item.seat_From !== undefined && item.seat_Thru !== undefined
                  ? `${item.seat_From} - ${item.seat_Thru}`
                  : null;

            return {
               ...item,
               buyers_email,
               buyer,
               marketplace,
               event_Date,
               tickets_Available_Date,
               broker_email,
               seats,
               status,
            };
         });

         // Store in cache and update previous cache key
         cache[cacheKey] = processedData;
         previousCacheKey = cacheKey;

         return processedData;
      } catch (err: any) {
         rejectWithValue("Failed to fetch invoices.");
      }
   },
);

export const transferInvoice = createAsyncThunk(
   "fulfillment/transferInvoice",
   async (params: { brokerEmail: string; invoiceId: string }, { rejectWithValue }) => {
      const { brokerEmail, invoiceId } = params;
      const queryParams = new URLSearchParams({
         brokerEmail,
         invoiceId,
      });

      const url = `/automate?${queryParams.toString()}`;

      try {
         const { data } = await automationInstance.post(url);
         return { message: data.message || JSON.stringify(data), invoiceId };
      } catch (error: any) {
         const errorResponse = error.response?.data?.error || error.message || JSON.stringify(error);
         return rejectWithValue(errorResponse);
      }
   },
);

const fulfillmentInvoicesSlice = createSlice({
   name: "fulfillment",
   initialState,
   reducers: {
      // removeOrdersData: (state) => ({ ...state, data: null }),
   },
   extraReducers: (builder) => {
      builder.addCase(fetchInvoices.pending, (state) => {
         return { ...state, isLoading: true };
      });
      builder.addCase(fetchInvoices.fulfilled, (state, action) => {
         return { ...state, isLoading: false, data: action.payload };
      });
      builder.addCase(fetchInvoices.rejected, (state, action) => {
         return { ...state, isLoading: false, data: null, error: action.error.message as string };
      });

      builder.addCase(transferInvoice.pending, (state, action) => {
         state.isLoading = false;
         state.isPending = true;
         state.pendingInvoiceId = action.meta.arg.invoiceId;
      });
      builder.addCase(transferInvoice.fulfilled, (state, action) => {
         state.isLoading = false;
         state.isPending = false;
         state.pendingInvoiceId = null;
      });
      builder.addCase(transferInvoice.rejected, (state, action) => {
         state.isLoading = false;
         state.isPending = false;
         state.error = action.error.message as string;
         state.pendingInvoiceId = null;
      });
   },
});

export const fulfillmentSliceState = (state: StoreStateType) => state.fulfillment;

export default fulfillmentInvoicesSlice.reducer;
