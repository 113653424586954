import { lazy, Suspense } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Loader from "../components/loader/Loader";
import { Loader100vh } from "../components/loader/Loader.styled";
import Layout from "../pages/layout/Layout";
import LoginRoute from "./LoginRoute";
import paths from "./paths";
import ProtectedRoute from "./ProtectedRoute";

const LazyLogin = lazy(() => import("../pages/login/Login"));
const LazyForgotPassword = lazy(() => import("../pages/forgotPassword/ForgotPassword"));
const LazyResetPassword = lazy(() => import("../pages/resetPassword/ResetPassword"));
const LazyInvoice = lazy(() => import("../pages/invoice/Invoice"));
const LazyFulfillment = lazy(() => import("../pages/fulfillment/Fulfillment"));
const LazyOnboarding = lazy(() => import("../pages/onboarding/Onboarding"));

const Routes = () => {
   return useRoutes([
      {
         element: (
            <ProtectedRoute>
               <Layout />
            </ProtectedRoute>
         ),
         children: [
            {
               path: paths.fulfillment,
               element: (
                  <Suspense fallback={<Loader isFullPage />}>
                     <LazyFulfillment />
                  </Suspense>
               ),
            },
            {
               path: paths.invoice,
               element: (
                  <Suspense fallback={<Loader isFullPage />}>
                     <ProtectedRoute>
                        <LazyInvoice />
                     </ProtectedRoute>
                  </Suspense>
               ),
            },
            {
               path: paths.onboarding,
               element: (
                  <ProtectedRoute>
                     <Suspense fallback={<Loader isFullPage />}>
                        <LazyOnboarding />
                     </Suspense>
                  </ProtectedRoute>
               ),
            },
         ],
      },
      {
         path: paths.login,
         element: (
            <LoginRoute>
               <Suspense
                  fallback={
                     <Loader100vh>
                        <Loader isFullPage />
                     </Loader100vh>
                  }>
                  <LazyLogin />
               </Suspense>
            </LoginRoute>
         ),
      },
      {
         path: paths.forgotPassword,
         element: (
            <LoginRoute>
               <Suspense
                  fallback={
                     <Loader100vh>
                        <Loader isFullPage />
                     </Loader100vh>
                  }>
                  <LazyForgotPassword />
               </Suspense>
            </LoginRoute>
         ),
      },
      {
         path: paths.resetPassword,
         element: (
            <LoginRoute>
               <Suspense
                  fallback={
                     <Loader100vh>
                        <Loader isFullPage />
                     </Loader100vh>
                  }>
                  <LazyResetPassword />
               </Suspense>
            </LoginRoute>
         ),
      },
      {
         path: paths.catchAll,
         element: <Navigate to={paths.fulfillment} />,
      },
   ]);
};

export default Routes;
