import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import BurgerIcon from "../../assets/icons/BurgerIcon";
import LogoutIcon from "../../assets/icons/LogoutIcon";
import useFullscreen from "../../hooks/useFullscreen";
import paths from "../../routes/paths";
import { removeToken } from "../../store/features/token/tokenSlice";
import { removeUser } from "../../store/features/user/userSlice";
import Dropdown, { OptionsType } from "../dropdown/Dropdown";
import FullScreen from "../fullScreen/FullScreen";
import { DropdownItemHolder } from "./Header.styled";
import HeaderDropdownLabel from "./HeaderDropdownLabel";
import FulfillmentIcon from "../../assets/icons/FulfillmentIcon";
import OnboardingIcon from "../../assets/icons/OnboardingIcon";

export let logout: any;

const HeaderDropdown = () => {
   const { active, handleFullscreen } = useFullscreen();
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const navigateToFulfillment = () => navigate(paths.fulfillment);
   const navigateToOnboarding = () => navigate(paths.onboarding);
   logout = () => {
      dispatch(removeToken());
      dispatch(removeUser());
   };
   const options: OptionsType[] = [
      {
         id: 1,
         label: (
            <DropdownItemHolder>
               <FulfillmentIcon height={18} width={18} />
               Fulfillment
            </DropdownItemHolder>
         ),
         func: navigateToFulfillment,
         isSubItem: false,
      },
      {
         id: 2,
         label: (
            <DropdownItemHolder>
               <OnboardingIcon height={18} width={18} />
               Onboarding
            </DropdownItemHolder>
         ),
         func: navigateToOnboarding,
         isSubItem: false,
      },
      {
         id: 3,
         label: (
            <DropdownItemHolder>
               <FullScreen active={active} />
            </DropdownItemHolder>
         ),
         func: handleFullscreen,
      },
      {
         id: 4,
         label: (
            <DropdownItemHolder>
               <LogoutIcon width={18} height={18} /> Logout
            </DropdownItemHolder>
         ),
         func: logout,
      },
   ];
   return <Dropdown options={options} label={<HeaderDropdownLabel />} icon={<BurgerIcon />} headerColor="white" />;
};
export default HeaderDropdown;
