import { useState } from "react";
import { toast } from "sonner";

type FullscreenMethod =
   | "requestFullscreen"
   | "webkitRequestFullscreen"
   | "mozRequestFullscreen"
   | "msRequestFullscreen"
   | "exitFullscreen"
   | "webkitExitFullscreen"
   | "mozExitFullscreen"
   | "msExitFullscreen";

interface FullscreenMethods {
   requestMethod: FullscreenMethod;
   exitMethod: FullscreenMethod;
}

const getFullscreenMethods = (): FullscreenMethods => {
   const methods: FullscreenMethods = {
      requestMethod: "requestFullscreen",
      exitMethod: "exitFullscreen",
   };

   const browserPrefixes = ["webkit", "moz", "ms"];

   for (const prefix of browserPrefixes) {
      if (`${prefix}RequestFullscreen` in document.documentElement) {
         methods.requestMethod = `${prefix}RequestFullscreen` as FullscreenMethod;
         methods.exitMethod = `${prefix}ExitFullscreen` as FullscreenMethod;
         break;
      }
   }

   return methods;
};

const useFullscreen = () => {
   const [active, setActive] = useState(false);
   const { requestMethod, exitMethod } = getFullscreenMethods();

   const openFullscreen = async () => {
      try {
         const element = document.documentElement;

         if (element && !document.fullscreenElement && typeof (element as any)[requestMethod] === "function") {
            await ((element as any)[requestMethod] as () => Promise<void>)();
         }
      } catch (error) {
         toast.error("Error while opening/closing fullscreen", {
            style: { color: "red" },
            closeButton: true,
            duration: 5000,
         });
      }
   };

   const closeFullscreen = async () => {
      try {
         if (typeof (document as any)[exitMethod] === "function") {
            await ((document as any)[exitMethod] as () => Promise<void>)();
         }
      } catch (error) {
         toast.error("Error while opening/closing fullscreen");
      }
   };

   const handleFullscreen = async () => {
      setActive(!active);

      if (active) {
         await closeFullscreen();
      } else {
         await openFullscreen();
      }
   };

   return { active, handleFullscreen };
};

export default useFullscreen;
