import { useLocation, useNavigate } from "react-router-dom";
import FulfillmentIcon from "../../assets/icons/FulfillmentIcon";
import SideBarIcon from "../../assets/icons/SideBarIcon";
import paths from "../../routes/paths";
import {
   LogoLine,
   SideBarContainer,
   SideBarIconHolder,
   SideBarItem,
   SideBarTextHolder,
   SideBarToggleButton,
} from "./SideBar.styled";
import OnboardingIcon from "../../assets/icons/OnboardingIcon";

interface SideBarProps {
   collapsed?: boolean | undefined;
   toggleCollapsed?: () => void;
}

const SideBar = ({ collapsed, toggleCollapsed }: SideBarProps) => {
   const navigate = useNavigate();
   const location = useLocation();

   const navigateToFulfillment = () => navigate(paths.fulfillment);
   const navigateToOnboarding = () => navigate(paths.onboarding);

   const items = [
      {
         id: 1,
         label: "Fulfillment",
         func: navigateToFulfillment,
         icon: <FulfillmentIcon isActive={location.pathname === paths.fulfillment} />,
         path: paths.fulfillment,
      },
      {
         id: 2,
         label: "Onboarding",
         func: navigateToOnboarding,
         icon: <OnboardingIcon isActive={location.pathname === paths.onboarding} />,
         path: paths.onboarding,
      },
   ];

   const mappedItems = items?.map(({ id, label, icon, func, path }) => {
      const isActive = location.pathname === path;
      return (
         <SideBarItem onClick={func} key={id} $active={isActive}>
            <SideBarIconHolder>{icon}</SideBarIconHolder>
            {!collapsed && <SideBarTextHolder $active={isActive}>{label}</SideBarTextHolder>}
         </SideBarItem>
      );
   });

   return (
      <>
         <LogoLine />
         <SideBarContainer>
            {mappedItems}
            <SideBarToggleButton onClick={toggleCollapsed} $collapsed={collapsed}>
               <SideBarIcon />
            </SideBarToggleButton>
         </SideBarContainer>
      </>
   );
};

export default SideBar;
