import axios from "axios";
import logout from "../utils/logout";

const instance = axios.create({
   baseURL: process.env.REACT_APP_API_BASE_URL,
});

const automationInstance = axios.create({
   baseURL: process.env.REACT_APP_API_TRANSFER_URL,
   timeout: 600000,
});

instance.interceptors.request.use(async (config) => {
   const token = localStorage.automation_portal_token;
   const refreshToken = localStorage.automation_portal_refresh_token;
   const tokenExpiration = localStorage.automation_portal_token_expiration;

   if (tokenExpiration && new Date(tokenExpiration) <= new Date(Date.now() + 15 * 60 * 1000)) {
      try {
         const response = await axios.post(
            process.env.REACT_APP_API_BASE_URL + "/Authentication/refresh-token",
            { refreshToken },
            { headers: { Authorization: `Bearer ${token}` } },
         );

         localStorage.setItem("automation_portal_token", response.data.accessToken);
         localStorage.setItem(
            "automation_portal_token_expiration",
            new Date(Date.now() + 3 * 60 * 60 * 1000).toISOString(),
         );
         config.headers.Authorization = `Bearer ${response.data.accessToken}`;
         return Promise.resolve(config);
      } catch (error) {
         logout();
         return Promise.reject(error);
      }
   }
   return config;
});

instance.interceptors.response.use(
   (response) => {
      const refreshTokenExpiration = localStorage.automation_portal_refresh_token_expiration;
      if (refreshTokenExpiration && new Date(refreshTokenExpiration) <= new Date(Date.now() + 15 * 60 * 1000)) {
         logout();
      }
      return response;
   },
   (error) => {
      if (error.response && error.response.status === 401) {
         logout();
      }
      return Promise.reject(error);
   },
);

export { automationInstance };
export default instance;
