import { createSlice } from "@reduxjs/toolkit";
import { StoreStateType } from "../../app/store";
import { userLogin } from "../login/loginSlice";
import { fetchUser } from "../user/userSlice";

export type TokenSliceType = {
   rememberMe: boolean;
   token: string | null;
};

const initialState: TokenSliceType = {
   rememberMe: true,
   token: localStorage.automation_portal_token || null,
};

const tokenSlice = createSlice({
   name: "token",
   initialState,
   reducers: {
      removeToken: (state) => {
         localStorage.removeItem("automation_portal_token");
         return { ...state, token: null };
      },
      addToken: (state, action) => {
         localStorage.setItem("automation_portal_token", action.payload);
         return { ...state, token: action.payload };
      },
      toggleRememberMe: (state) => {
         return { ...state, rememberMe: !state.rememberMe };
      },
   },
   extraReducers: (builder) => {
      builder.addCase(userLogin.rejected, (state) => {
         localStorage.removeItem("automation_portal_token");
         return { ...state, token: null };
      });
      builder.addCase(userLogin.fulfilled, (state, action) => {
         localStorage.setItem("automation_portal_token", action.payload?.accessToken);
         localStorage.setItem("automation_portal_refresh_token", action.payload?.refreshToken);
         localStorage.setItem(
            "automation_portal_token_expiration",
            new Date(Date.now() + 3 * 60 * 60 * 1000).toISOString(),
         );
         localStorage.setItem(
            "automation_portal_refresh_token_expiration",
            new Date(Date.now() + 3 * 24 * 60 * 60 * 1000).toISOString(),
         );
         return { ...state, token: action.payload?.accessToken };
      });
      builder.addCase(fetchUser.rejected, (state) => {
         localStorage.removeItem("automation_portal_token");
         return { ...state, token: null };
      });
   },
});

export const tokenSliceState = (state: StoreStateType) => state.token;
export const { addToken, removeToken, toggleRememberMe } = tokenSlice.actions;

export default tokenSlice.reducer;
