import { GlobalAuthorizationType } from "../../app/store.types";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instance, { automationInstance } from "../../../service/instance";
import { toast } from "sonner";
import { OnboardingData } from "../../../pages/onboarding/OnboardingTable/OnboardingTable.constants";

export type OnboardedStatisticsType = {
   onboardedEmail: number;
   invoiceFulfilled: number;
   thanLastWeek: number;
};
export type OnboardedStatisticsSliceType = {
   [key: string]: OnboardedStatisticsType;
};

export type OnboardedSliceType = {
   companyId: string;
   status: string;
   email: string;
   messageStatus: string;
   lastAttempted: string;
};

export type OnboardedType = {
   statistics: OnboardedStatisticsSliceType;
   accounts: OnboardedSliceType[];
};

export type OnboardedSliceTypes = GlobalAuthorizationType & {
   data: OnboardedType | null;
};

const initialState: OnboardedSliceTypes = {
   isLoading: false,
   data: null,
   error: null,
};

interface FetchOnboardedParams {
   companyId: string;
   status: string;
   email: string;
}

export const resetPasswordOnboarding = createAsyncThunk(
   "onboarding/resetPassword",
   async (params: { email: string }, { rejectWithValue }) => {
      const { email } = params;
      const url = "/reset-password";

      try {
         const { data } = await automationInstance.post(url, { email });
         return { message: data.message || JSON.stringify(data) };
      } catch (error: any) {
         const errorResponse = error.response?.data?.error || error.message || JSON.stringify(error);
         return rejectWithValue(errorResponse);
      }
   },
);

export const onboardingAccount = createAsyncThunk(
   "onboarding/onboardingAccount",
   async (params: { email: string }, { rejectWithValue }) => {
      const { email } = params;
      const url = "/onboard";

      try {
         const { data } = await automationInstance.post(url, { email });
         return { message: data.message || JSON.stringify(data) };
      } catch (error: any) {
         const errorResponse = error.response?.data?.error || error.message || JSON.stringify(error);
         return rejectWithValue(errorResponse);
      }
   },
);

export const fetchOnboardedAccounts = createAsyncThunk(
   "onboarding/fetchOnboardedEmails",
   async (params: FetchOnboardedParams, { rejectWithValue }) => {
      const { companyId, status, email } = params;

      const storage = sessionStorage.getItem("companies");
      const id = storage ? JSON.parse(storage).find((company: any) => company.name === companyId)?.companyId : null;

      const queryParams = new URLSearchParams({
         companyId: id || "",
         status,
         email,
      });

      const url = `/Accounts/OnboardingAccounts?${queryParams.toString()}`;
      try {
         const { data } = await instance.get(url);
         return data.map((item: OnboardingData) => {
            const lastAttempted = item.lastAttempted ? item.lastAttempted.substring(0, 10) : null;
            return {
               ...item,
               lastAttempted,
            };
         });
      } catch (err: any) {
         toast.error("Failed to fetch onboarded accounts.");
      }
   },
);
const onboardedAccountsSlice = createSlice({
   name: "onboarding",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(fetchOnboardedAccounts.pending, (state) => {
         return { ...state, isLoading: true };
      });
      builder.addCase(fetchOnboardedAccounts.fulfilled, (state, action) => {
         return { ...state, isLoading: false, data: action.payload };
      });
      builder.addCase(fetchOnboardedAccounts.rejected, (state, action) => {
         return { ...state, isLoading: false, data: null, error: action.error.message as string };
      });

      builder.addCase(resetPasswordOnboarding.pending, (state, action) => {
         state.isLoading = false;
         state.isPending = true;
      });
      builder.addCase(resetPasswordOnboarding.fulfilled, (state, action) => {
         state.isLoading = false;
         state.isPending = false;
      });
      builder.addCase(resetPasswordOnboarding.rejected, (state, action) => {
         state.isLoading = false;
         state.isPending = false;
         state.error = action.error.message as string;
      });

      builder.addCase(onboardingAccount.pending, (state, action) => {
         state.isLoading = false;
         state.isPending = true;
      });
      builder.addCase(onboardingAccount.fulfilled, (state, action) => {
         state.isLoading = false;
         state.isPending = false;
      });
      builder.addCase(onboardingAccount.rejected, (state, action) => {
         state.isLoading = false;
         state.isPending = false;
         state.error = action.error.message as string;
      });
   },
});
export default onboardedAccountsSlice.reducer;
