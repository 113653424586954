import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import instance from "../../../service/instance";
import customCatch from "../../../utils/customCatch";
import { StoreStateType } from "../../app/store";
import { GlobalAuthorizationType } from "../../app/store.types";

const defaultColumnSettings = {
   EventName: true,
   Section: true,
   Row: true,
   Qty: true,
   Seats: true,
   Cost: true,
   Marketplace: true,
   EventDate: true,
   CreationDate: true,
   Status: true,
   Email: true,
};

export type UserSettingsType = {
   emailWhenPoCreated?: boolean;
};

export type UserColumnSettingsType = {
   EventName: boolean;
   Section: boolean;
   Row: boolean;
   Qty: boolean;
   Cost: boolean;
   Marketplace: boolean;
   EventDate: boolean;
   CreationDate: boolean;
   Status: boolean;
   Email: boolean;
};

export type UserType = {
   firstName: string;
   lastName: string;
   email: string;
   password: string;
   active: boolean;
   company: string;
   lastLoginTime: Date;
   settings: UserSettingsType;
   columnSettings: UserColumnSettingsType;
};

export type UserSliceType = GlobalAuthorizationType & { user: UserType | null };

const initialState: UserSliceType = {
   isLoading: false,
   user: null,
   error: null,
};

export const fetchUser = createAsyncThunk("user/fetchUser", async () => {
   return null;
});

const userSlice = createSlice({
   name: "user",
   initialState,
   reducers: {
      removeUser: (state) => {
         return { ...state, user: null };
      },
      editUser: (state, action) => {
         return { ...state, user: { ...state.user, ...action.payload } };
      },
      toggleSettingsCheckbox: (state, action) => {
         const currentValue = state.user?.columnSettings[action.payload as keyof UserColumnSettingsType];
         state.user!.columnSettings[action.payload as keyof UserColumnSettingsType] = !currentValue;
      },
      setDefaultSettings: (state) => {
         state.user!.columnSettings = defaultColumnSettings;
      },
      setColumnSettings: (state, action) => {
         state.user!.columnSettings = action.payload;
      },
   },
   extraReducers: (builder) => {
      builder.addCase(fetchUser.pending, (state) => {
         return { ...state, isLoading: true };
      });
      builder.addCase(fetchUser.fulfilled, (state, action) => {
         return { ...state, isLoading: false, user: action.payload };
      });
      builder.addCase(fetchUser.rejected, (state, action) => {
         return { ...state, isLoading: false, user: null, error: action.error.message as string };
      });
   },
});

export const userSliceState = (state: StoreStateType) => state.user;
export const { removeUser, toggleSettingsCheckbox, setDefaultSettings, setColumnSettings, editUser } =
   userSlice.actions;

export default userSlice.reducer;
