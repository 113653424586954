type StageFrontLogoType = {
   width?: string;
};

const StageFrontLogo = ({ width }: StageFrontLogoType) => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width ?? 215} viewBox="0 0 215 107" fill="none">
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M107.481 18.444C127.503 18.444 143.732 34.1356 143.732 53.4942C143.732 72.8529 127.503 88.5445 107.481 88.5445C87.4595 88.5445 71.2302 72.8529 71.2302 53.4942C71.2302 34.1356 87.4595 18.444 107.481 18.444Z"
            fill="#EC6728"
         />
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.127 106.756C11.1294 92.8191 0.112061 74.0903 0.112061 53.4944C0.112061 32.8984 11.1294 14.1697 29.127 0.232788C16.0622 14.9346 8.27905 33.4158 8.27905 53.4944C8.27905 73.5729 16.0622 92.043 29.127 106.756ZM185.835 0.232788C203.833 14.1697 214.85 32.8871 214.85 53.4944C214.85 74.1016 203.821 92.8191 185.835 106.756C198.9 92.043 206.683 73.5729 206.683 53.4944C206.683 33.4158 198.9 14.9458 185.835 0.232788Z"
            fill="white"
         />
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48.2756 95.7547C34.6872 84.6974 26.3574 69.8382 26.3574 53.4941C26.3574 37.1501 34.6872 22.2908 48.2756 11.2335C38.4101 22.8982 32.5233 37.5662 32.5233 53.4941C32.5233 69.422 38.3984 84.0788 48.2756 95.7547ZM166.673 11.2448C180.262 22.302 188.591 37.1613 188.591 53.5054C188.591 69.8494 180.262 84.7087 166.673 95.766C176.539 84.1013 182.425 69.4332 182.425 53.5054C182.425 37.5775 176.55 22.9207 166.673 11.2448Z"
            fill="white"
         />
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M63.4817 84.9003C53.3835 76.6889 47.1943 65.6429 47.1943 53.4945C47.1943 41.3461 53.3835 30.3114 63.4817 22.0887C56.1524 30.7613 51.7781 41.6611 51.7781 53.4945C51.7781 65.3279 56.1524 76.2277 63.4817 84.9003ZM151.469 22.1C161.567 30.3114 167.756 41.3574 167.756 53.5058C167.756 65.6541 161.567 76.6889 151.469 84.9115C158.798 76.2389 163.172 65.3392 163.172 53.5058C163.172 41.6724 158.798 30.7726 151.469 22.1Z"
            fill="white"
         />
      </svg>
   );
};

export default StageFrontLogo;
