import { createSlice } from "@reduxjs/toolkit";
import { StoreStateType } from "../../app/store";

const themeLS = localStorage.automation_portal_theme;
const initialState: string = themeLS || "Light";

const themeSlice = createSlice({
   name: "theme",
   initialState,
   reducers: {
      setTheme: (_state, action) => {
         localStorage.setItem("automation_portal_theme", action.payload);
         return action.payload;
      },
   },
});

export const themeSliceState = (state: StoreStateType) => state.theme;
export const { setTheme } = themeSlice.actions;

export default themeSlice.reducer;
