import { AxiosError } from "axios";
import { toast } from "sonner";

type ErrorResponse = {
   message: string;
};

const customCatch = (err: AxiosError, customMessage?: string) => {
   const axiosErrorMsg = (err.response?.data as ErrorResponse)?.message ?? err.response?.data ?? err.message;
   const finalMsg = customMessage ?? axiosErrorMsg;
   toast.error(finalMsg, {
      style: { color: "red" },
      closeButton: true,
      duration: 5000,
      id: Date.now(),
   });
   throw new Error(finalMsg);
};

export default customCatch;
