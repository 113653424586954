import { configureStore } from "@reduxjs/toolkit";
import forgotPasswordSliceReducer from "../../store/features/forgotPassword/forgotPasswordSlice";
import themeSliceReducer from "../../store/features/theme/themeSlice";
import { InvoiceSliceType } from "../features/invoices/invoiceSlice";
import loginSliceReducer from "../features/login/loginSlice";
import resetPasswordSliceReducer from "../features/resetPassword/resetPasswordSlice";
import tokenSliceReducer, { TokenSliceType } from "../features/token/tokenSlice";
import userSliceReducer, { UserSliceType } from "../features/user/userSlice";
import userUpdatesSliceReducer, { UserUpdatesType } from "../features/user/userUpdatesSlice";
import { GlobalAuthorizationType } from "./store.types";
import fulfillmentSliceReducer, { FulfillmentSliceTypes } from "../features/fulfillment/fulfillmentSlice";
import companySliceReducer, { CompanyState } from "../features/fulfillment/companySlice";
import fulfillmentFiltersSliceReducer from "../features/fulfillment/fulfillmentFiltersSlice";
import onboardedAccountsSliceReducer from "../features/onboarding/onboardingSlice";

export type StoreStateType = {
   theme: string;
   user: UserSliceType;
   login: GlobalAuthorizationType;
   forgotPassword: GlobalAuthorizationType;
   resetPassword: GlobalAuthorizationType;
   token: TokenSliceType;
   invoices: InvoiceSliceType;
   fulfillment: FulfillmentSliceTypes;
   userUpdates: UserUpdatesType;
   support: GlobalAuthorizationType;
   companies: CompanyState;
};

const store = configureStore({
   reducer: {
      theme: themeSliceReducer,
      user: userSliceReducer,
      login: loginSliceReducer,
      forgotPassword: forgotPasswordSliceReducer,
      resetPassword: resetPasswordSliceReducer,
      token: tokenSliceReducer,
      userUpdates: userUpdatesSliceReducer,
      fulfillment: fulfillmentSliceReducer,
      companies: companySliceReducer,
      fulfillmentFilters: fulfillmentFiltersSliceReducer,
      onboarding: onboardedAccountsSliceReducer,
   },
});

export default store;
